// 创建文件en/index.js
const en = {
  // 路由
  Index: 'Guanya',
  Rolex: 'Rolex',
  TuDor: 'Tudor',
  IWC: 'IWC',
  CarTier: 'Cartier',
  Paneral: 'Paneral',
  Information: 'Information',
  Search: 'Search',
  Vip: 'Vip',
  Address: 'Address',
  Love: 'Love',
  Order: 'Order',
  Login: 'Login',
  Register: 'Register',
  Series: 'Series',
  Details: 'Details',
  Modify: 'Modify',
  InformationDetails: 'Information Details',
  //
  AllBrands: 'All Brands',
  Service: 'Service',
  Upkeep: 'Upkeep',
  TermsForUsage: 'Terms For Usage',
  PrivacyPolicy: 'Privacy Policy',
  vipArea: 'vip Area',
  ReservationService: 'Reservation Service',
  Appellation: 'Appellation',
  Mr: 'Mr',
  Ms: 'Ms',
  FirstName: 'FirstName',
  lastName: 'lastName',
  Phone: 'Phone',
  Email: 'Email',
  Time: 'Time',
  Store: 'Store',
  Submit: 'Submit',
  More: 'More',
  WatchesRecommended: 'Watches Recommended',
  BrandRecommend: 'Brand Recommend',
  BuyWatch: 'Buy Watch',
  IMG: 'IMG',
  All: 'All',
  MyWatches: 'My Watches',
  SavedWatches: 'Saved Watches',
  PurchaseRecord: 'Purchase Record',
  WatchBrand: 'Watch Brand',
  Account: 'Account',
  Verification: 'Verification',
  ExploreMore: 'More',
  Case: 'Case',
  Movement: 'Movement',
  Features: 'Features',
  Explore: 'Explore',
  ContinueExplore: 'Explore',
  Name: 'Name',
  CellPhone: 'Cell Phone',
  DateBirth: 'Date of Birth',
  Job: 'Job',
  Hobby: 'Hobby',
  Sumbit: 'Submit',
  Points: 'Points',
  CardNumber: 'Card Number',
  ModifyData: 'Modify Data',
  LogOut: 'Log Out',
  CurrentTime: 'Current Time',
  PurchaseHistor: 'Purchase Histor',
  Type: 'Type',
  Quantity: 'Quantity',
  Money: 'Money',
  ConsumerShop: 'Consumer Shop',
  ConsumptionTime: 'Consumption Time',
  Home: 'Home',
  InformationHome: 'Information Home',
  seconds: 'seconds',
  Message: 'Message',

  // 语句翻译
  PleaseEnterYourSearch: 'Please enter your search',
  ScanQRCodes: 'Scan QR Codes',
  CustomerService: 'Customer Service',
  PleaseSelectStore: 'Please select an appointment store',
  ThankYou: 'Thank You',
  ReservationSuccess: 'Reservation success, thank you for your support for Guan Ya Watch City',
  RecoverMessage: 'Our representative will read the message and reply as soon as possible',
  SendVerification: 'Send',
  DiscoverFamily: 'DISCOVER THE FAMILY',
  ViewWatches: 'VIEW THE WATCHES',
  NoImages: 'No set picture',
  WatchSeries: 'Watch Series',
  AddWishlist: 'Add to Wishlist',
  Unadd: 'Unadd',
  LoginSuccessful: 'Login Successful',
  RegisteredSuccessfully: 'Registered Successfully',
  ModifiedSuccessfully: 'Modified Successfully',
  AlreadyMember: 'Already a member',
  NewUser: 'New User Registration',
  LoginExpired: 'Login expired, please login again',
  PersonalInformationModification: 'Personal Information Modification',
  NoData: 'No Data',
  TechnicalDetails: 'Technical Details',
  NoFound: 'No relevant watch was found',
  NotBrandFound: 'No watches were found in this brand',
  RequestTimedOut: 'Request timed out, please try again',
  ServerError: 'Server Error',
  RequestFailed: 'Request failed, please check the network',
  JoinGuanYa:
    'I am willing to join the Guanya Membership Program and agreed to comply with the "Personal Data Collection Statement," and "Privacy Policy".',

  Please1: 'Please enter',
  Please2: 'Please enter',
  Please3: 'Please select',
  Please4: 'Please enter',
  Please5: 'Formatting error',
  Please6: 'Formatting error',
  Please7: 'Please enter',
  Please8: 'Please select',
  Please9: 'Please enter',
  Please10: 'Please enter',
  Please11: 'Please check',
  Please12: 'Please enter',
};
export default en;
