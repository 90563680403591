// 创建文件zh/index.js
const zh = {
  // 路由
  Index: '冠亚',
  Rolex: '劳力士',
  TuDor: '帝舵',
  IWC: '万国',
  CarTier: '卡地亚',
  Paneral: '沛纳海',
  Information: '资讯',
  Search: '搜索',
  Vip: '名峰会vip',
  Address: '销售店铺',
  Love: '您的最爱',
  Order: '预约',
  Login: '登录',
  Register: '注册',
  Series: '系列',
  Details: '详情',
  Modify: '修改',
  InformationDetails: '资讯详情',

  //
  AllBrands: '全部品牌',
  Service: '服务',
  Upkeep: '保养及检修',
  TermsForUsage: '使用条款',
  PrivacyPolicy: '隐私声明',
  vipArea: '名峰会vip专区',
  ReservationService: '预约服务',
  Appellation: '称呼',
  Mr: '先生',
  Ms: '女士',
  FirstName: '名字',
  lastName: '姓氏',
  Phone: '电话',
  Email: '电子邮箱',
  Time: '预约时间',
  Store: '预约门店',
  Submit: '提交预约',
  More: '查看更多',
  WatchesRecommended: '腕表推荐',
  BrandRecommend: '精品推荐',
  BuyWatch: '选购腕表',
  IMG: '图片',
  All: '全部',
  MyWatches: '我的腕表清单',
  SavedWatches: '已保存的腕表',
  PurchaseRecord: '我的购买记录',
  WatchBrand: '手表品牌',
  Account: '账号',
  Verification: '验证码',
  ExploreMore: '探索更多',
  Case: '表壳',
  Movement: '机芯',
  Features: '特点',
  Explore: '探索',
  ContinueExplore: '继续探索',
  Name: '姓名',
  CellPhone: '手机号码',
  DateBirth: '出生日期',
  Job: '工作',
  Hobby: '爱好',
  Sumbit: '提交',
  Points: '积分',
  CardNumber: '卡号',
  ModifyData: '修改资料',
  LogOut: '退出登录',
  CurrentTime: '当前时间',
  Type: '型号',
  Quantity: '数量',
  Money: '金额',
  ConsumerShop: '消费店铺',
  ConsumptionTime: '消费时间',
  New: '新款',
  Home: '主页',
  InformationHome: '资讯主页',
  seconds: '秒',
  Message: '留言',

  // 语句翻译
  PleaseEnterYourSearch: '请输入搜索内容',
  ScanQRCodes: '扫码关注微信公众号',
  CustomerService: '感谢您联络客户服务',
  PleaseSelectStore: '请选择预约门店',
  ThankYou: '谢谢',
  ReservationSuccess: '预约成功，感谢您对于冠亚名表城的支持',
  RecoverMessage: '我们的代表将细阅留言，并尽快回复。',
  SendVerification: '发送验证码',
  DiscoverFamily: '探索腕表系列',
  ViewWatches: '浏览腕表',
  NoImages: '当前没有设置图片',
  WatchSeries: '浏览腕表系列',
  AddWishlist: '添加至心愿单',
  Unadd: '取消添加',
  LoginSuccessful: '登录成功!',
  RegisteredSuccessfully: '注册成功',
  ModifiedSuccessfully: '修改成功',
  AlreadyMember: '已是会员',
  NewUser: '新用户注册',
  LoginExpired: '登录过期，请重新登录',
  PersonalInformationModification: '个人信息修改',
  NoData: '暂无数据',
  TechnicalDetails: '技术细节',
  NoFound: '没有找到相关腕表',
  NotBrandFound: '在该品牌中没有找到相关腕表',
  RequestTimedOut: '请求超时，请重试',
  ServerError: '服务器错误',
  RequestFailed: '请求失败，请检查网络',
  JoinGuanYa:'本人愿意加入冠亚会员计划，并且已阅读、理解并同意遵守《冠亚会员计划条款和细则》、《收集个人资料证明》及《隐私政策》。',


  Please1: '请输入名字',
  Please2: '请输入姓氏',
  Please3: '请选择地区',
  Please4: '请输入手机号码',
  Please5: '请输入有效的手机号',
  Please6: '请输入有效的电子邮件地址',
  Please7: '请输入验证码',
  Please8: '请选择出生日期',
  Please9: '请输入其他内容',
  Please10: '请输入姓名',
  Please11: '请先勾选同意协议',
  Please12: '请输入内容',
};
export default zh;
