import service from './http';

/**  
 * get 方法需要参数拼接在后面
 * {
    params: data,
  }

  正常写则是post
 */

// 系列 传品牌名     详情 传id
export function getWatchInfo(data) {
  return service.post('/gy/watch/getWatchInfo', data);
}
// 表头，表尾，幻灯片
export function getRouteList(data) {
  return service.post('/gy/watch/getRouteList', data);
}
// 劳力士
export function rolxIndex(data) {
  return service.get('/gy/rolx/index', {
    params: data,
  });
}
// 品牌主页
export function getSeriesList(data) {
  return service.get('/gy/watch/getSeriesList', {
    params: data,
  });
}
// 地址
export function getStoreList(data) {
  return service.get('/gy/watch/getStoreList', {
    params: data,
  });
}
// 喜爱   ~~~~~~~
export function insertWishWatch(data) {
  return service.post('/insertOne?op_id=gy_service.insertWishWatch', data);
}
// 资讯
export function getInformation(data) {
  return service.get('/gy/watch/getInformation', {
    params: data,
  });
}
// 预约
export function sendEmail(data) {
  return service.post('/gy/send/sendEmail', data);
}

// 账号密码登录
export function login(data) {
  return service.post('/token/api/v1/sys/login', data);
}
// 验证码登录
export function smslogin(data) {
  return service.post('/gy/send/smslogin', data);
}
// 注册
export function register(data) {
  return service.post('/gy/send/register', data);
}
// 退出登录 !
export function logout(data) {
  return service.get('/api/v1/sys/logout', data);
}
// 获取账号信息 ！
export function getVIPInfo(data) {
  return service.get('/select?op_id=gy_service.getVIPInfo', data);
}
// 修改账号信息 ！
export function updateVIP(data) {
  return service.post('/updateOne?op_id=gy_service.updateVIP', data);
}
// 忘记密码
export function resetPassword(data) {
  return service.post('/gy/send/resetPassword', data);
}
// 第一次登录
export function firstLogin(data) {
  return service.post('/api/v1/sys/firstLogin', data);
}

// /gy/send/sms 这个是发送短信的，telphone 手机号和 smsType 注册就是register 登录就是smsLogin、改密码就是resetPassword
export function sms(data) {
  return service.post('/gy/send/sms', data);
}

// 登录后更新本地cookie
export function getWishWatch(data) {
  return service.get('/select?op_id=gy_service.getWishWatch', data);
}
// 获取购买记录
export function getConsumeList(data) {
  return service.get('/select?op_id=gy_service.getConsumeList', data);
}

// 切换语言
export function changeLanguage(data) {
  return service.get('/gy/send/changeLanguage', {
    params: data,
  });
}

// 常量参数
export function getHobby(data) {
  return service.get('/gy/watch/getHobby', data);
}
export function getJob(data) {
  return service.get('/gy/watch/getJob', data);
}
